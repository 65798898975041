import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

import buildBrand from "../../img/buildBrand.png"
import positionCampaign from "../../img/position.png"
import relevantResearch from "../../img/research.png"

const HowTo = () => {
  return (
    <div className={styles.HowTo}>
      <div className={styles.body}>
        <Reveal>
          <div className={styles.title}>How to get after it:</div>
        </Reveal>
        <Reveal>
          <div className={styles.rowReverse}>
            <img src={buildBrand} className={styles.rowImg} />
            <div className={styles.rowCopy}>
              <h3>Research to be relevant.</h3>
              <div className={styles.details}>
                Talk with a diverse group of parents, grandparents, and
                guardians across Oregon. Understand their attitudes and
                behaviors around finances, savings, and education — and more
                importantly, how they view success for themselves and their
                kids.{" "}
              </div>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.row}>
            <img src={positionCampaign} className={styles.rowImg} />
            <div className={styles.rowCopy}>
              <h3>Build an approachable brand.</h3>
              <div className={styles.details}>
                Develop a unique and versatile brand identity that speaks to
                parents and guardians of a geographically, socioeconomically,
                and racially diverse audience.
              </div>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.rowReverse}>
            <img src={relevantResearch} className={styles.rowImg} />
            <div className={styles.rowCopy}>
              <h3>Position the campaign.</h3>
              <div className={styles.details}>
                Plan, create, and launch targeted awareness campaigns on the
                right channels at the right times to attract, educate, and
                convert new&nbsp;members.
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  )
}

export default HowTo
