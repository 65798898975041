import React from "react"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

import one from "../../img/1.png"
import two from "../../img/2.png"
import three from "../../img/3.png"
import four from "../../img/4.png"

const NumberedList = () => {
  return (
    <div className={styles.NumberedList}>
      <div className={styles.list}>
        <Reveal>
          <div className={styles.title}>
            To do this, OCSP&nbsp;needed&nbsp;to:
          </div>
          <div className={styles.rowOne}>
            <div
              className={styles.row}
              style={{ marginTop: "61px", marginRight: "75px" }}
            >
              <img src={one} className={styles.number} />
              <div className={styles.body}>
                Understand what drives folks to open an OCSP account.
              </div>
            </div>

            <div className={styles.row} style={{ marginTop: "61px" }}>
              <img src={two} className={styles.number} />
              <div className={styles.body}>
                Understand what barriers these folks face.
              </div>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.rowTwo}>
            <div
              className={styles.row}
              style={{ marginTop: "61px", marginRight: "75px" }}
            >
              <img src={three} className={styles.number} />
              <div className={styles.body}>
                Refresh the brand’s creative approach to broaden folk appeal.
              </div>
            </div>

            <div className={styles.row} style={{ marginTop: "70px" }}>
              <img src={four} className={styles.number} />
              <div className={styles.body}>
                Educate folks on how the program can help them save for their
                kids’ future, no matter what their financial situation.
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  )
}

export default NumberedList
