import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

//Styles
import styles from "./styles.module.less"
//Images

import hero from "./img/BabyGrad.png"

export default class Marquee extends Component {
  render() {
    return (
      <div className={styles.marquee}>
        <Reveal>
          <div className={styles.body}>
            <div className={styles.heroImg}>
              <img src={hero} className={styles.hero} />
            </div>
            <div className={styles.headlines}>
              <div className={styles.preheader}>
                case study: <br />
                the oregon college savings plan
              </div>
              <div className={styles.header}>
                Saving money for college is easy &mdash; when there’s a plan.
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    )
  }
}
