import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

import Hero from "../sectionHeader"

//images
import mediaPlanHero from "./img/mediaPlanHero.png"
import triangle from "./img/triangle.png"
import checklist from "./img/checklist.png"

import youtube from "./img/youtube.png"
import spotify from "./img/spotify.png"
import hulu from "./img/hulu.png"
import twitter from "./img/twitter.png"
import facebook from "./img/facebook.png"
import pandora from "./img/pandora.png"
import instagram from "./img/instagram.png"

import howItWorksImg from "./img/InvestmentOptions.png"

import arrow from "./img/arrow.png"

import metrics from "./img/theNumbers.png"
import projections from "./img/projections.png"

const MediaPlan = () => {
  return (
    <div className={styles.MediaPlan}>
      <div style={{ backgroundColor: "#F2FDFF", paddingTop: "50px" }}>
        <Hero
          image={mediaPlanHero}
          title="Spread the good word with a great"
          keyWord="media&nbsp;plan."
          body="During our research, we identified how consumers’ media habits had changed — they were hanging out in places OCSP was not. So we crafted a crafty media strategy to meet potential customers where they, in fact, are: on streaming video platforms like Hulu, social media channels, and streaming audio platforms like Pandora."
        />
      </div>
      <div className={styles.body}>
        <div className={styles.socialMedia}>
          <Reveal>
            <div className={styles.icons}>
              <div className={styles.iconDetails}>
                <img src={triangle} className={styles.icon} />
                <p className={styles.iconCopy}>
                  New media partners helped engage users at their respective
                  stages in the buyer journey.{" "}
                </p>
              </div>
              <div className={styles.iconDetails}>
                <img src={checklist} className={styles.icon} />
                <p className={styles.iconCopy}>
                  Testing competing channels (e.g., Pandora vs. Spotify) helped
                  tailor our next marketing spend.
                </p>
              </div>
            </div>
          </Reveal>
          <Reveal>
            <h4>We launched the updated brand across a mix of new channels.</h4>

            <div className={styles.socialMediaIcons}>
              <div className={styles.columnOne}>
                <img src={youtube} className={styles.socialIcons} />
                <img src={facebook} className={styles.socialIcons} />
                <img src={instagram} className={styles.socialIcons} />
                <img src={twitter} className={styles.twitter} />
              </div>

              <div className={styles.columnTwo}>
                <img src={hulu} className={styles.socialIcons} />
                <img src={pandora} className={styles.socialIcons} />
                <img src={spotify} className={styles.socialIcons} />
              </div>
            </div>
          </Reveal>
          <Reveal>
            <div className={styles.socialIconsCopy}>
              <p>
                New users visited the website. Cross-channel retargeting
                opportunities arose. And awareness was increased across the
                state&nbsp;— particularly in areas previously underexposed.
              </p>
            </div>
          </Reveal>
        </div>

        <div className={styles.HIWSection}>
          <div className={styles.howItWorks}>
            {/* HERO SECTION  */}
            <Reveal>
              <div className={styles.hiwHero}>
                <img src={howItWorksImg} className={styles.hiwHeroImg} />
                <div className={styles.hiwHeroCopy}>
                  <h1>How it worked out:</h1>
                  <div className={styles.hiwHeroDetails}>
                    We elevated the brand’s look and feel to stand out in the
                    crowd. And we showcased new creative in a statewide,
                    multichannel digital campaign using email, social media,
                    streaming video, and audio-only platforms to grow brand
                    awareness, change perceptions, increase site traffic, and
                    generate conversions at the same time.
                  </div>
                </div>
              </div>
            </Reveal>
            <Reveal>
              <div className={styles.numbersIntro}>
                <h5>What the numbers look like.</h5>
                <div className={styles.socialMetrics}>
                  Facebook, Instagram, Hulu, Pandora, Spotify, and Google Ads
                  performance:
                </div>
              </div>
            </Reveal>
            {/* START METRICS SECTION  */}

            <div className={styles.stats}>
              <Reveal>
                <div className={styles.percentageDetails}>
                  <img src={arrow} className={styles.arrowImg} />
                  <h2>516.7%</h2>
                  <div className={styles.details}>
                    increase in total digital impressions
                  </div>
                </div>
              </Reveal>
              <Reveal>
                <div className={styles.percentageDetails}>
                  <img src={arrow} className={styles.arrowImg} />
                  <h2>584.7%</h2>
                  <div className={styles.details}>increase in unique reach</div>
                </div>
              </Reveal>
              <Reveal>
                <div className={styles.percentageDetails}>
                  <img src={arrow} className={styles.arrowImg} />
                  <h2>152%</h2>
                  <div className={styles.details}>
                    increase in website traffic
                  </div>
                </div>
              </Reveal>
              <Reveal>
                <div className={styles.percentageDetails}>
                  <img src={arrow} className={styles.arrowImg} />
                  <h2>6,332</h2>
                  <div className={styles.details}>
                    clicks driven by YouTube (41% of total)
                  </div>
                </div>
              </Reveal>
              <Reveal>
                <div className={styles.percentageDetails}>
                  <img src={arrow} className={styles.arrowImg} />
                  <h2>171,210</h2>
                  <div className={styles.details}>
                    unique users reached by paid social (53% of total — with
                    only 6% of spend)
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
          <div className={styles.metricsDetails}>
            <Reveal>
              <div className={styles.metricsOne}>
                <img src={projections} className={styles.metricsDetailsImg} />
                <div className={styles.copyColumn}>
                  <div className={styles.metricsDetailsTitle}>
                    Q1 of ’21 was the best quarter&nbsp;to&nbsp;date!
                  </div>

                  <div className={styles.metricsDetailsCopy}>
                    Comparing Q1 of 2020 to Q1 of 2021, there was an{" "}
                    <strong className={styles.q1Percents}>839.5% </strong>
                    increase in impressions, a{" "}
                    <strong className={styles.q1Percents}>1,296.5%</strong>{" "}
                    increase in total clicks, and a{" "}
                    <strong className={styles.q1Percents}>48.6%</strong>{" "}
                    increase in OCSP’s click-through rate.
                    <br /> <br />
                    Quarter over quarter, there was a{" "}
                    <strong className={styles.q1Percents}>19.2%</strong>{" "}
                    increase in account opens and a{" "}
                    <strong className={styles.q1Percents}>49.6%</strong>{" "}
                    increase in direct site traffic from our campaigns.
                  </div>
                </div>
              </div>
            </Reveal>
            <Reveal>
              <div className={styles.metricsTwo}>
                <img src={metrics} className={styles.metricsDetailsImg} />
                <div className={styles.copyColumn}>
                  <div className={styles.metricsDetailsTitle}>
                    What the numbers mean.
                  </div>

                  <div className={styles.metricsDetailsCopy}>
                    <h3>
                      {" "}
                      The Oregon College Savings Plan is officially on the
                      Oregon map.
                    </h3>{" "}
                    <p style={{ textAlign: "left", margin: "10px 0px" }}>
                      We connected new communities across the state with the
                      OCSP brand. With targeted, tailored messaging within
                      previously unexplored platforms, we drastically increased
                      their reach while also improving ad effectiveness — which
                      means more people now know they can save for their kids’
                      future (which means more winners in the
                      game&nbsp;of&nbsp;life).
                    </p>
                  </div>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaPlan
