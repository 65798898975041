import React from "react"

import OCSP from "components/CaseStudies/OCSPcase"
import Layout from "components/Layout"
import SEO from "components/seo"

const OCSPCaseStudy = () => (
  <Layout backgroundColor={"white"} bannerBgColor="black" isArchive>
    <SEO
      title="Thesis | Case study: Oregon College Savings Plan"
      description="We partnered with The Oregon College Savings Plan to plan and build a multichannel campaign to raise brand awareness, change perceptions, and convert new members. Read the case study."
    />
    <OCSP />
  </Layout>
)

export default OCSPCaseStudy
