import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

import CaseStudyNav from "./components/CaseStudyNav"

const Credits = () => {
  return (
    <div className={styles.Credits}>
      <div className={styles.conclusion}>
        <div className={styles.h6}>Credits</div>
        <div className={styles.text3}>
          Thanks to the Oregon College Savings Plan team, AKA the real people
          who made the real&nbsp;work.
          <br />
          <b>Account Management:</b> KC&nbsp;Anderson <br />
          <b>Project Management:</b> Matt&nbsp;Monnin <br />
          <b>Strategy:</b> Scott&nbsp;Lansing, Zach&nbsp;Galehouse,
          McKenna&nbsp;Albion, Chris&nbsp;Morales, Jake&nbsp;Cheek,
          Julien&nbsp;Demers
          <br />
          <b>Writing:</b> Elizabeth&nbsp;Harney, Annie&nbsp;Russell,
          Margaret&nbsp;Park&nbsp;Bridges
          <br />
          <b>Design:</b> Michael&nbsp;Rosenau, Adam&nbsp;Pollack,
          Ellen&nbsp;Skiff, Eli&nbsp;Caudillo, Chandler&nbsp;Subra
          <br />
          <b>Motion:</b> Marina&nbsp;Valentina
          <br />
          <b>Development:</b> Cemal&nbsp;Richards, Madelaine&nbsp;Trujillo,
          Josh&nbsp;Reid
        </div>
      </div>

      <CaseStudyNav />
    </div>
  )
}

export default Credits
