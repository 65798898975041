import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

import Header from "../sectionHeader"

//styles
import styles from "./styles.module.less"

//img

import strategyHero from "./img/stratHero.png"

import nine from "./img/9.png"
import fifteen from "./img/15.png"
import twentyOne from "./img/21.png"
import twentyFive from "./img/25.png"
import twoHundredPlus from "./img/224.png"
import oneThousandPlus from "./img/1215.png"

import bgBlob from "./img/BG-blob.png"

const Strategy = () => {
  return (
    <div className={styles.strategy}>
      {/* START HERO SECTION  */}
      <div style={{ backgroundColor: "#f2fdff" }}>
        <Header
          image={strategyHero}
          title="Strong results start with a strong"
          keyWord="strategy."
          body="Working with people’s money meant understanding their hopes, fears, and priorities. And working with the Oregon Treasury meant navigating a highly regulated financial industry. Any chance of success required a deep dive into Oregonians’ saving habits, financial goals, and industry perceptions. Cue the research plan!"
        />
      </div>
      {/* END HERO SECTION  */}
      {/* START TWO PARAGRAPH SECTION */}
      <Reveal>
        <div className={styles.twoColumn}>
          <div className={styles.column}>
            <h3 style={{ maxWidth: "90%" }}>Get to know the&nbsp;people.</h3>
            <p>
              By completing several primary research phases, we assessed
              Oregonians’ experiences, perceptions of the brand, and the drivers
              and barriers to opening an OCSP account.
            </p>
          </div>
          <div className={styles.copyBreak}>
            <img
              src={
                "https://static1.squarespace.com/static/5af989f150a54f8953f641f0/t/60545901b423c06b6cd5ae2d/1616140546285/TaxesGrandparents_1.png"
              }
            />
          </div>
          <div className={styles.column}>
            <h3>Get to know the&nbsp;platforms.</h3>
            <p>
              To better inform our media buying, we identified the media
              channels that potential account holders use to consume news
              and&nbsp;entertainment.
            </p>
          </div>
        </div>
      </Reveal>
      {/* END TWO PARAGRAPH SECTION  */}
      {/* START NUMBER COLUMN LIST  */}
      <div className={styles.numbersList}>
        <Reveal>
          <div className={styles.stats}>
            <img src={twentyFive} className={styles.statImg} />
            <h4>exploratory interviews</h4>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.stats}>
            <img src={twentyOne} className={styles.statImg} />
            <h4>qualitative interviews</h4>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.stats}>
            <img src={twoHundredPlus} className={styles.statImg} />
            <h4>zip codes represented</h4>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.stats}>
            <img src={fifteen} className={styles.statImg} />
            <h4>counties</h4>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.stats}>
            <img src={nine} className={styles.statImg} />
            <h4>cities</h4>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.stats}>
            <img src={oneThousandPlus} className={styles.statImg} />
            <h4>surveys conducted</h4>
          </div>
        </Reveal>
      </div>
      {/* END NUMBERS LIST  */}
      <div className={styles.finalSection}>
        {/* <img src={bgBlob} className={styles.blob} /> */}
        <Reveal>
          <div className={styles.blobCopy}>
            Critically, we went outside Oregon’s major metro areas, venturing
            beyond the I-5 corridor to have the most diverse conversations
            possible. We combined in-person interviews with digital surveys to
            inform a multichannel awareness campaign grounded in real numbers
            ... and&nbsp;real&nbsp;people.
          </div>
        </Reveal>
      </div>
    </div>
  )
}
export default Strategy
