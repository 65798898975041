import React from "react"

import Reveal from "react-reveal/Reveal"

import Marquee from "./components/Marquee"
import ProblemStatement from "./components/ProblemStatement"
import Strategy from "./components/Strategy"
import Creative from "./components/Creative"
import MediaPlan from "./components/MediaPlan"
import Credits from "./components/Credits"

const OCSP = () => (
  <div>
    <Marquee />

    <ProblemStatement />

    <Strategy />

    <Creative />

    <MediaPlan />

    <Credits />
  </div>
)

export default OCSP
