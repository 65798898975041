import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

//components
import Header from "../sectionHeader"
//styles
import styles from "./styles.module.less"

//img
import creativeHero from "./img/creativeHero.png"
import picnic from "./img/picnic.png"
import couch from "./img/couch.png"
import sketches from "./img/sketches.png"
import sketchesDesktop from "./img/sketches-desktop.png"
import kiteOne from "./img/kiteSketchOne.png"
import kiteTwo from "./img/kiteSketchTwo.png"
import kiteThree from "./img/kiteSketchThree.png"
import gifPH from "./img/stillGIF.png"
import macbook from "./img/Macbook.png"
import pandora from "./img/Pandora.png"
import ipad from "./img/iPad.png"
const Creative = () => {
  const reel =
    "https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/OCSP_Reel_v02.mp4"

  const gif =
    "https://eroi-s3.s3.us-west-2.amazonaws.com/thesis-web/BabyGrad_divider_v4.mp4"

  return (
    <div className={styles.creative}>
      <Header
        image={creativeHero}
        title="Make your mark with compelling"
        keyWord="creative."
        body="Nobody likes to talk about finances, which makes branding a financial institution that much trickier. Brands are often super straitlaced, excessively comedic, or overly earnest — none of which felt quite right for the dynamic OCSP brand."
        subBody="So, how do you talk about what people don’t want to?"
      />

      <div className={styles.intro}>
        <Reveal>
          <div className={styles.introCopyBlock}>
            <h3>Reimagine your look.</h3>
            <p>
              Refresh the brand identity and introduce a signature illustration
              style that’s uniquely OCSP. Inspired by strategy, we aimed to
              resonate with everyone, beyond class, race, and geographic
              divides.
            </p>
          </div>
        </Reveal>
        <div style={{ marginBottom: "15px" }}>
          <Reveal>
            <img src={picnic} className={styles.introImg} />
          </Reveal>
          <Reveal>
            <img src={couch} className={styles.introImg} />
          </Reveal>
        </div>
        <Reveal>
          <div className={styles.introCopyBlock}>
            <h3>Push your own boundaries.</h3>
            <p>
              Use familiar shapes and approachable metaphors to simplify complex
              financial topics. We pushed past static graphics and brought the
              campaign to life with signature stylized illustrations and
              animations.
            </p>
          </div>
        </Reveal>
      </div>
      <Reveal>
        <img src={sketches} className={styles.sketches} />
        <img src={sketchesDesktop} className={styles.sketchesDesktop} />
      </Reveal>

      <div className={styles.kitesSection}>
        <Reveal>
          <div className={styles.kiteImages}>
            <img src={kiteOne} className={styles.kites} />
            <img src={kiteTwo} className={styles.kites} />
            <img src={kiteThree} className={styles.kites} />
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.gifContainer}>
            <video
              className={styles.gif}
              src={gif}
              loop
              autoPlay
              muted
              playsInline
            ></video>
          </div>
        </Reveal>
        {/* <div className={styles.sectionIntro}> */}
        <Reveal>
          <div className={styles.bodyCopyBlock}>
            <h3>Give the brand a voice.</h3>
            <p>
              Silent films are a thing of the past. OCSP needed strong scripts,
              engaging music, and a warm, approachable voice to breathe life
              into the campaign. We found just the right fit.
            </p>
          </div>
        </Reveal>
      </div>
      <Reveal>
        <div className={styles.videoContainer}>
          <video
            className={styles.video}
            src={reel}
            muted={false}
            controls
            playsInline
          ></video>
        </div>
      </Reveal>
      {/* </div> */}

      <div className={styles.onlineDisplay}>
        <div className={styles.onlineDisplayBody}>
          <Reveal>
            <div className={styles.bodyCopyBlock}>
              <h3>Keep it cohesive.</h3>
              <p>
                The animation spots, audio ads, social media posts, and website
                landing pages all used an integrated visual language. We applied
                our illustrative style across channels to streamline and unify
                the user experience.
              </p>
            </div>
          </Reveal>
          <Reveal>
            <img src={macbook} />
          </Reveal>
          <Reveal>
            <div className={styles.mobileImgs}>
              <img src={ipad} className={styles.ipad} />
              <img src={pandora} className={styles.iPhone} />
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  )
}

export default Creative
