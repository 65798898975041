import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"

import NumberedList from "./components/NumberedList"
import HowTo from "./components/howTo"

import background from "./img/KinderGradBG.png"
import highFive from "./img/HighFiveGif.gif"

const ProblemStatement = () => {
  return (
    <div className={styles.ProblemStatement}>
      <div className={styles.body}>
        <Reveal>
          <div className={styles.brandAwareness}>
            <div className={styles.headline}>
              Building brand awareness with barrier-breaking campaigns.
            </div>
            <div className={styles.subHeadline}>
              The Oregon College Savings Plan (OCSP) helps Oregon families save
              for their kids’ future education &mdash; including college, trade
              school, apprenticeships, and more.
            </div>
          </div>
          <div className={styles.pageBreakImgs}>
            <img src={background} className={styles.bg} />
            <img src={highFive} className={styles.shapes} />
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.heroCopy}>
            <div className={styles.heroCopyTitle}> But why stop there?</div>
            <div className={styles.heroCopyBody}>
              OCSP wanted to broaden its reach and diversify its audience
              geographically, socioeconomically, and racially. By understanding
              families’ needs throughout the&nbsp;ninth-largest state, we could
              craft more relevant messaging for all Oregonians.
            </div>
          </div>
        </Reveal>
      </div>
      {/* <Reveal> */}

      <NumberedList />

      <HowTo />
    </div>
  )
}

export default ProblemStatement
