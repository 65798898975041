import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"

//styles
import styles from "./styles.module.less"

//img

import heroBG from "./img/heroBG.png"

const Headers = ({ image, title, keyWord, body, subBody }) => {
  return (
    <div>
      {/* START HERO SECTION  */}
      <div className={styles.hero}>
        <Reveal>
          <div className={styles.heroContent}>
            <img src={image} className={styles.heroImg} />
            <div className={styles.copy}>
              <div className={styles.heroTitle}>
                {title} <strong>{keyWord}</strong>
              </div>

              {/* <div className={styles.heroKeyWord}>{keyWord}</div> */}

              <div className={styles.heroBody}>{body}</div>
              <div className={styles.heroBody}>{subBody}</div>
            </div>
          </div>
        </Reveal>
      </div>
    </div>
  )
}
export default Headers
